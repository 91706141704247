import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { create, ExportMenu, createFromConfig, Container, addLicense, useTheme } from '@amcharts/amcharts4/core';
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";
import * as am4plugins_sunburst from "@amcharts/amcharts4/plugins/sunburst";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

// eslint-disable-next-line react-hooks/rules-of-hooks
useTheme(am4themes_animated);

// https://www.amcharts.com/docs/v4/concepts/performance/
// am4core.options.queue = true;
// am4core.options.onlyShowOnViewport = true;

addLicense("CH250111316");

export const getNewChartId = () => {
  return `chart-div-${Math.random() * 10000000}`;
};

/**
 *
 * @typedef {"bar"|"column"|"xy"|"pie"|"guage"|"radar"|"serial"|"sliced"|"container"|"treemap"|"forcedirectedtree"|"sunburst"|"container"} ChartType
 */

/**
 *
 * @param {ChartType} type
 */
export function parseChartType(type) {
  switch (type.toLowerCase()) {
    case ('bar' || 'column' || 'xy'):
      return am4charts.XYChart;
    case 'pie':
      return am4charts.PieChart;
    case 'guage':
      return am4charts.GaugeChart;
    case 'radar':
      return am4charts.RadarChart;
    case 'serial':
      return am4charts.SerialChart;
    case 'sliced':
      return am4charts.SlicedChart;
    case 'container':
      return Container;
    case 'treemap':
      return am4charts.TreeMap;
    case 'forcedirectedtree':
      return am4plugins_forceDirected.ForceDirectedTree;
    case 'sunburst':
      return am4plugins_sunburst.Sunburst;
    default:
      return Container;
  }
}

export function setupChartOptions(chart, data, options={isExportable: false, exportPdf: null, modifyExport: null}) {
  if (!chart) { return false; }
  if (options.isExportable) {
    if (!chart.exporting.menu) {
      chart.exporting.menu = new ExportMenu();
    }
    if (options.exportPdf) {
      const exists = chart.exporting.menu.items[0].menu
              .findIndex(v => v.label == "Save PDF" && v.type == "custom");
      if (exists !== -1) {
        chart.exporting.menu.items[0].menu[exists].options.callback = function () {
          options.exportPdf(chart, data);
        };
      } else {
        chart.exporting.menu.items[0].menu.push({
          label: "Save PDF",
          type: "custom",
          options: {callback: function () {
            options.exportPdf(chart, data);
          }}
        });
      }
    }
    if (options.modifyExport) {
      options.modifyExport(chart, data);
    }
  }
}

const Chart = (props,) => {
  const idRef = useRef(props.id || getNewChartId());
  const chartRef = useRef();
  const chartType = useRef();

  const {
    data,
    isExportable,
    style,
    type,

    onChartReady,
    onDataReady,
    exportPdf,
    modifyExport,
  } = {
    isExportable: false,
    style: {},
    ...props,
  };

  // Memoize data to prevent expensive re-renders
  const memoizedData = React.useMemo(() => {
    return onDataReady ? onDataReady(data) : data;
  }, [data, onDataReady]);

  function setupChart() {
    if (!chartType.current) {
      chartType.current = parseChartType(type);
    }

    if (!chartRef.current) {
      if (props.options) {
        chartRef.current = createFromConfig(props.options, idRef.current, chartType.current);
      } else {
        chartRef.current = create(idRef.current, chartType.current);
      }
      setupChartOptions(chartRef.current, memoizedData,  {
        isExportable,
        exportPdf,
        modifyExport,
      });
      if (onChartReady) {
        onChartReady(chartRef.current, memoizedData);
      }
    }
  }

  // Handle component unmounting
  React.useEffect(() => {
    return () => {
      chartRef.current && chartRef.current.dispose();
    };
  }, []);

  // Setup chart
  React.useEffect(() => {
    setupChart();
    if (props.updateChart && chartRef.current) {
      props.updateChart(chartRef.current, memoizedData);
    }
  }, [isExportable, memoizedData, onChartReady, type]);

  // Load data into chart
  React.useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data = memoizedData;
      setupChartOptions(chartRef.current, memoizedData,  {
        isExportable,
        exportPdf,
        modifyExport,
      }); // need to call again to load new data
    }
  }, [memoizedData]);

  return <div
    id={idRef.current}
    style={{ width: props.width || "100%", height: props.height || "350px", ...style }}
    onClick={props.onClick}
/>;
};

Chart.propTypes = {
  data: PropTypes.array.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isAnimated: PropTypes.bool,
  isExportable: PropTypes.bool,
  style: PropTypes.object,
  type: PropTypes.string,
  onChartReady: PropTypes.func,
  onDataReady: PropTypes.func,
  exportPdf: PropTypes.func,
  modifyExport: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  onClick: PropTypes.func,
  updateChart: PropTypes.func,
};

export default Chart;
